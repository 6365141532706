






































































import { Maybe } from 'graphql/jsutils/Maybe'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BButton, BModal, BFormInput, BFormSelect, BFormSelectOption } from 'bootstrap-vue'
import terminalModule from '@/store/terminal'
import { FragmentListTerminalFragment } from '@/graphql/default/terminal.graphql'

@Component({
  components: {
    BButton,
    BModal,
    BFormInput,
    BFormSelect,
    BFormSelectOption
  }
})
export default class ServiceMoveAlert extends Vue {
  selectValue = '';
  inputValue = '';
  selectedTerminal:Maybe<FragmentListTerminalFragment> = null;

  @Prop({ type: String, default: 'service-move-alert' })
  id!:string

  get terminals() {
    return terminalModule.getters.terminals
  }

  get saleTerminals() {
    return terminalModule.getters.saleTerminals
  }

  get terminalsOptions() {
    return this.saleTerminals && this.saleTerminals.map(item => ({
      value: this.formatId(item?.id),
      text: item?.address
    }))
  }

  onButtonClick(result: boolean) {
    this.$emit('button-clicked', { confirm: result, salesPointId: this.selectedTerminal?.id })
    this.$bvModal.hide(this.id)
  }

  findTerminal(id: string) {
    if (id !== this.selectValue) {
      this.selectValue = ''
    } else {
      this.inputValue = ''
    }
    this.selectedTerminal = this.terminals?.find(item => this.formatId(item?.id) === id)
  }

  formatId(id: string | undefined) {
    return id && atob(id).replace('TerminalListType:', '')
  }
}
