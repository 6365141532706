import { render, staticRenderFns } from "./serviceBasket.vue?vue&type=template&id=100bb188&scoped=true&"
import script from "./serviceBasket.vue?vue&type=script&lang=ts&"
export * from "./serviceBasket.vue?vue&type=script&lang=ts&"
import style0 from "./serviceBasket.vue?vue&type=style&index=0&id=100bb188&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "100bb188",
  null
  
)

export default component.exports